import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Obfuscate from "react-obfuscate"; // um Telefonnummer und E-Mail vor Spam zu schützen
import ContentContainer from "../components/contentcontainer"
import SEO from "../components/seo"

export default function Imprint({ data }) {

  return (
    <div>
      <Layout siteTitle="Impressum">
        <SEO
          title="Impressum | Babyfotografie Köln"
          description="Fotografin für ihre natürlichen und liebevollen Babybauch- und Babyfotos in Köln und Umgebung. Reserviert euren Termin jetzt online. Viele Bilder, Preise und Infos."
        />
        <ContentContainer>
          <div>
            <h5>Kontakt</h5>
            <p>
              Telefon: <Obfuscate tel="+49 221 95490630" className="text-decoration-none"></Obfuscate><br />
            E-Mail: <Obfuscate email="jovana@&shy;klitzekleinundmagisch.de"></Obfuscate>
            </p>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
        </ContentContainer>
      </Layout>
    </div>
  )
}

/*
  Diese Funktion holt sich mit GraphQL den Markdown wird gefiltert nach dem Slug-Tag in der Datei
  in "html" ist dann das geparste Markdown als HTML String zu finden
  Übergeben werden die Daten an die Seitenfunktion über "data".
  Der Inhalt von Data lässt sich dann über {data.xxx} verwenden.
  Der HTML-String lässt sich über dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} injezieren
*/
export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: {slug: {eq: "/content/imprint"}}) {
      frontmatter {
        date
        slug
        title
      }
      html
      fileAbsolutePath
    }
  }
`